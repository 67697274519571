.boxHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #043f47;
    color: white;
    padding: 10px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.colorW {
    color: aliceblue;
}

.coloresderickymorty {
    color: #40b5cb;
    color: #c0df40;
}


@media (max-width: 650px) {
    .boxHeader {
        flex-direction: column;
        align-items: center;
    }
}
