.RickAndMortyApp {
  display: flex;
  flex-direction: column;
  background-color: #043f47;
}

/* rgb(70, 170, 163) */

.bodyProject {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.Characters {
  margin-top: 100px;
}

.long {
  height: 100%;
}

.Sidebar {
  width: 200px;
}

.bg-light.container-fluid.pt-5 {
  flex-grow: 1;
}

.centerCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}