.bodyCharacters {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    padding: 15px;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.boxCharacters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.boxImage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    
}

.imgCharacter {
    border-radius: 50%;
    width: 160px;
    object-fit: cover;
    border: 2px solid #000000;
}

.paddingColor {
    border: 5px solid #c0df40;
    border-radius: 20px;
}

.boxDescription {
    background-color: #40b5cb;
    padding-top: 50px;
    width: 250px;
    color: aliceblue;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    border: 2px solid #000000;
    text-align: center;
}

.hr {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 10px 0;

}

.shadowText {
    text-shadow: 1px 1px 2px #000000;

}

.myColor1 {
    color: #c0df40;
}

.myColor2 {
    color: #bb2828;
}

@media (max-width: 890px) {
    .boxDescription {
        width: 200px;
    }
}

@media (max-width: 490px) {

    .bodyCharacters {
        padding-top: 150px;
        justify-content: start;
    }

    .paddingColor {
        width: 100%;
    }

    .boxCharacters {
        flex-direction: row;
        width: 100%;
        
    }

    .boxImage {
        top: 0px;
    }

    .imgCharacter {
        width: 120px;
        z-index: 1;
    }

    .paddingColor {
        position: relative;
        right: 50px;
        z-index: 0;
    }

    .boxDescription {
        padding-left: 50px;
        padding-top: 0px;
        width: 100%;
    }

    .boxDescription h4 {
        font-size: 1.2rem;
        margin-top: 10px;
        margin-right: 15px;
    }

    .hr {
        margin: 5px 0;
    }
}